import React, { useEffect, useState, useCallback, useRef } from 'react'
import './FlyingShapes.css'
import { getRandomShapes } from '../../utils/randomShapeIcon'
import { petColor } from '../../constants/petColor'
import { nanoid } from 'nanoid'

const FlyingShapes = ({ x, y, isTurboMode, character }) => {
    const [shapes, setShapes] = useState([])
    const animationTimer = useRef(null)
    const cachedShapes = useRef([])

    useEffect(() => {
        cachedShapes.current = getRandomShapes(isTurboMode)
    }, [isTurboMode])

    const createShapes = useCallback(() => {
        return cachedShapes.current.map(Shape => ({
            component: Shape,
            directionX: Math.random() * 2 - 1,
            directionY: Math.random() * 2 - 1,
            id: nanoid(),
            x,
            y,
        }))
    }, [x, y])

    useEffect(() => {
        const updatedShapes = createShapes()
        setShapes(updatedShapes)

        if (animationTimer.current) {
            clearTimeout(animationTimer.current)
        }

        animationTimer.current = setTimeout(() => {
            setShapes([])
        }, 1000)

        return () => {
            if (animationTimer.current) {
                clearTimeout(animationTimer.current)
            }
        }
    }, [createShapes])

    return (
        <>
            {shapes.map(({ component: Shape, directionX, directionY, id }) => (
                <div
                    key={id}
                    className={`flying-shape ${isTurboMode ? 'turbo-mode' : ''}`}
                    style={{
                        top: y,
                        left: x,
                        '--direction-x': directionX,
                        '--direction-y': directionY,
                        color: petColor[character],
                    }}
                >
                    <Shape />
                </div>
            ))}
        </>
    )
}

export default React.memo(FlyingShapes, (prevProps, nextProps) => {
    return (
        prevProps.x === nextProps.x &&
        prevProps.y === nextProps.y &&
        prevProps.isTurboMode === nextProps.isTurboMode &&
        prevProps.character === nextProps.character
    )
})
