import React, { useState, useCallback, useMemo } from 'react'
import './CharacterSelection.css'
import infoIcon from '../../images/icons/info-icon.png'
import InformationSection from './../InformationSection/InformationSection'
import { sounds } from '../../utils/sounds'

const ArrowButton = React.memo(({ direction, onClick, isLoading }) => {
    return (
        <button type="button" className={`action-${direction}-arrow`} onClick={onClick} disabled={isLoading}></button>
    )
})

const SelectButton = React.memo(({ onClick, isLoading }) => {
    return (
        <button type="button" className="btn-select" onClick={onClick} disabled={isLoading}>
            Select
        </button>
    )
})

const CharacterSelection = ({ onCharacterSelected }) => {
    const [selectedCharacter, setSelectedCharacter] = useState(0)
    const [showPopup, setShowPopup] = useState(false)
    const [popupContent, setPopupContent] = useState('')
    const [popupButtonText, setPopupButtonText] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const characters = useMemo(
        () => [
            { name: 'main', displayName: 'Tiger', imageSrc: require('../../assets/gif_characters/Tiger_Idle-min.gif') },
            {
                name: 'pet1',
                displayName: 'Tarsius',
                imageSrc: require('../../assets/gif_characters/Tarsius_Idle-min.gif'),
            },
            {
                name: 'pet2',
                displayName: 'Peacock',
                imageSrc: require('../../assets/gif_characters/Peafowl_Idle-min.gif'),
            },
            {
                name: 'pet3',
                displayName: 'Capybara',
                imageSrc: require('../../assets/gif_characters/Capybara_Idle-min.gif'),
            },
            {
                name: 'pet4',
                displayName: 'Monkey',
                imageSrc: require('../../assets/gif_characters/Monkey_Idle-min.gif'),
            },
        ],
        [],
    )

    const handleInfoClick = useCallback(() => {
        setPopupContent(
            <div className="popup-text-content">
                <p>Choose your fantastic</p>
                <p>POOMB PET</p>
                <p>to start</p>
                <p>EARNING $COGA TOKENS!</p>
                <p>Choose carefully and begin the money farming journey!</p>
                <p>Joy-enjoy, Poomb-Poomb :)</p>
            </div>,
        )
        setPopupButtonText("LET'S GO")
        setShowPopup(true)
    }, [])

    const handleClosePopup = useCallback(() => setShowPopup(false), [])

    const handleSelectClick = useCallback(async () => {
        sounds.select.play()

        if (selectedCharacter !== null) {
            setIsLoading(true)
            await onCharacterSelected(characters[selectedCharacter].name)
            setIsLoading(false)
        }
    }, [selectedCharacter, onCharacterSelected, characters])

    const handleNextSlide = useCallback(() => {
        sounds.change.play()
        setSelectedCharacter(prev => (prev + 1) % characters.length)
    }, [characters.length])

    const handlePrevSlide = useCallback(() => {
        sounds.change.play()

        setSelectedCharacter(prev => (prev - 1 + characters.length) % characters.length)
    }, [characters.length])

    return (
        <div className="page">
            <div className="container">
                <div className="character-selection-wrapper">
                    <div className="info-left-icon" onClick={handleInfoClick}>
                        <img src={infoIcon} alt="Info Icon" className="info-icon" />
                    </div>

                    <div className="select-character-wrapper">
                        <div className="character-item-slider">
                            {characters.map((character, index) => {
                                const isPrev = (selectedCharacter - 1 + characters.length) % characters.length === index
                                const isCenter = selectedCharacter === index
                                const isNext = (selectedCharacter + 1) % characters.length === index

                                return (
                                    <div
                                        key={character.name}
                                        className={`pet-item ${isCenter ? 'center' : ''} ${
                                            isPrev ? 'prev' : isNext ? 'next' : ''
                                        }`}
                                        style={{
                                            transform: isCenter ? 'scale(1.1)' : 'scale(0.9)',
                                            display: isCenter || isPrev || isNext ? 'block' : 'none',
                                        }}
                                    >
                                        {isCenter && <div className="character-label">{character.displayName}</div>}
                                        <img
                                            src={character.imageSrc}
                                            alt={character.displayName}
                                            className={`character-image ${isCenter ? 'large' : ''}`}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="action-select-wrapper">
                        <ArrowButton direction="left" onClick={handlePrevSlide} isLoading={isLoading} />
                        <SelectButton onClick={handleSelectClick} isLoading={isLoading} />
                        <ArrowButton direction="right" onClick={handleNextSlide} isLoading={isLoading} />
                    </div>
                </div>
            </div>

            {showPopup && (
                <InformationSection onClose={handleClosePopup} content={popupContent} buttonText={popupButtonText} />
            )}
        </div>
    )
}

export default CharacterSelection
