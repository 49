import { Howl } from 'howler'
import changePetSoundMP3 from '../assets/sounds/UI_Button_Pet_Select.mp3'
import changePetSoundWAV from '../assets/sounds/UI_Button_Pet_Select.wav'
import selectPetSoundMP3 from '../assets/sounds/UI_Button_Pet_Buy.mp3'
import selectPetSoundWAV from '../assets/sounds/UI_Button_Pet_Buy.wav'
import closeMenuSoundMP3 from '../assets/sounds/UI_Swipe_Woosh.mp3'
import closeMenuSoundWAV from '../assets/sounds/UI_Swipe_Woosh.wav'
import getRewardSoundMP3 from '../assets/sounds/UI_Button_AdventureDay.mp3'
import getRewardSoundWAV from '../assets/sounds/UI_Button_AdventureDay.wav'
import buttonClickSoundMP3 from '../assets/sounds/UI_General_Button_7.mp3'
import buttonClickSoundWAV from '../assets/sounds/UI_General_Button_7.wav'
import approveUpgradeSoundMP3 from '../assets/sounds/UI_General_Approve3.mp3'
import approveUpgradeSoundWAV from '../assets/sounds/UI_General_Approve3.wav'
import petTapSoundMP3 from '../assets/sounds/SFX_Pet_TapPoomb.mp3'
import petTapSoundWAV from '../assets/sounds/SFX_Pet_TapPoomb.wav'
import turboModeSoundMP3 from '../assets/sounds/SFX_Runner_Coin_Pickup.mp3'
import turboModeSoundWAV from '../assets/sounds/SFX_Runner_Coin_Pickup.wav'

const createSoundPool = (soundSrc, poolSize = 10, volume = 0.5) => {
    const pool = Array.from(
        { length: poolSize },
        () =>
            new Howl({
                src: soundSrc,
                volume: volume,
                preload: true,
                // html5: true,
                // onload: () => console.log(`Sound loaded: ${soundSrc}`),
                onloaderror: (id, error) => console.error(`Failed to load sound: ${soundSrc}`, error),
                onplayerror: function () {
                    this.once('unlock', function () {
                        // console.log(`Sound unlocked: ${soundSrc}`)
                    })
                },
            }),
    )

    let index = 0

    return {
        play: () => {
            pool[index].play()
            index = (index + 1) % poolSize
        },
        preload: () => {
            pool.forEach(sound => sound.load())
        },
        unload: () => {
            pool.forEach(sound => sound.unload())
        },
        unlock: () => {
            pool.forEach(sound => {
                sound.once('unlock', () => {
                    // console.log(`Unlocked sound: ${soundSrc}`)
                })
            })
        },
    }
}

export const sounds = {
    change: createSoundPool([changePetSoundMP3, changePetSoundWAV]),
    select: createSoundPool([selectPetSoundMP3, selectPetSoundWAV]),
    close: createSoundPool([closeMenuSoundMP3, closeMenuSoundWAV]),
    reward: createSoundPool([getRewardSoundMP3, getRewardSoundWAV]),
    click: createSoundPool([buttonClickSoundMP3, buttonClickSoundWAV]),
    approve: createSoundPool([approveUpgradeSoundMP3, approveUpgradeSoundWAV]),
    tap: createSoundPool([petTapSoundMP3, petTapSoundWAV]),
    turbo: createSoundPool([turboModeSoundMP3, turboModeSoundWAV]),
}
