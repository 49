export const subscriptionNames = Object.freeze({
    instagram_poomb: 'Subscribe Poomb Instagram',
    instagram_comet_space: 'Subscribe Comet Space Instagram',
    x_poomb: 'Subscribe Poomb X',
    x_comet_space: 'Subscribe Comet Space X',
    tiktok_poomb: 'Subscribe Poomb TikTok',
    tiktok_comet_space: 'Subscribe Comet Space TikTok',
    facebook_poomb: 'Subscribe Poomb Facebook',
    facebook_comet_space: 'Subscribe Comet Space Facebook',
    linkedin_comet_space: 'Subscribe Comet Space Linkedin',
    youtube_comet_space: 'Subscribe Comet Space Youtube',
    telegram_comet_space: 'Subscribe Comet Space Telegram',
    discord_poomb: 'Subscribe Poomb Discord',
    website_comet_space_visited: 'Visit Comet Space web site',
    website_poomb_visited: 'Visit Poomb web site',
})
