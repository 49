import { getUTCSeconds } from './getUTCSeconds'

export const calculateTimeToNextTick = () => {
    const currentSeconds = getUTCSeconds()
    if (currentSeconds < 30) {
        return 30 - currentSeconds
    } else {
        return 60 - currentSeconds
    }
}
