import React, { useState } from 'react';
import './RankInfo.css';

const RangInfo = ({ closeRangInfo }) => {
    const [activeTab, setActiveTab] = useState('tab1'); 

    const renderTable = () => {
        if (activeTab === 'tab1') {
            return (
                <table>
                    <tbody>
                        <tr>
                            <td>Data 1</td>
                            <td>Data 2</td>
                            <td>Data 3</td>
                            <td>Data 4</td>
                        </tr>
                        <tr>
                            <td>Data 5</td>
                            <td>Data 6</td>
                            <td>Data 7</td>
                            <td>Data 8</td>
                        </tr>
                    </tbody>
                </table>
            );
        } else if (activeTab === 'tab2') {
            return (
                <table>
                    
                    <tbody>
                        <tr>
                            <td>Data A1</td>
                            <td>Data A2</td>
                            <td>Data A3</td>
                            <td>Data A4</td>
                        </tr>
                        <tr>
                            <td>Data B1</td>
                            <td>Data B2</td>
                            <td>Data B3</td>
                            <td>Data B4</td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    };

    return (
        <div className="rang-info-overlay">
            <div className="rang-info-content">
                <div className="close-btn" onClick={closeRangInfo}>
                    <img src={require('../../images/icons/close.png')} alt="Close" className="close-icon" />
                </div>

                <div className="tabs">
                    <button
                        className={activeTab === 'tab1' ? 'active-tab' : ''}
                        onClick={() => setActiveTab('tab1')}
                    >
                      Friends
                    </button>
                    <button
                        className={activeTab === 'tab2' ? 'active-tab' : ''}
                        onClick={() => setActiveTab('tab2')}
                    >
                       Global
                    </button>
                </div>

                <div className="table-content">{renderTable()}</div>
            </div>
        </div>
    );
};

export default RangInfo;
