import React, { useEffect, useState } from 'react'
import './CoinAnimation.css'
import coin from '../../images/icons/coin.png'

const animations = ['flyUpZigzag', 'flyUpWave', 'flyUpSway', 'flyUpGentle']

const CoinAnimation = ({ x, y, removeCoin }) => {
    const [animationClass] = useState(() => {
        return animations[Math.floor(Math.random() * animations.length)]
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            removeCoin()
        }, 1500)

        return () => clearTimeout(timer)
    }, [removeCoin])

    return (
        <div className={`coin-animation ${animationClass}`} style={{ top: `${y}px`, left: `${x}px` }}>
            <img src={coin} alt="Coin" width={30} />
        </div>
    )
}

export default CoinAnimation
