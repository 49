import React, { useState } from 'react'
import './InfoMenu.css'
import clsx from 'clsx'
import LeftMenuIcon from '../../images/icons/left-menu-disable.webp'
import RightDisableMenuIcon from '../../images/icons/right-menu.webp'
import { upgradePlayerBuffs } from '../../api/playersApi'
import { sounds } from '../../utils/sounds'

const InfoMenu = ({
    telegramId,
    closeMenu,
    playerUpgrades,
    fetchPlayerUpgrades,
    spendCoinsForUpgrade,
    coins,
    selectedCharacter,
    handleFlyCoin,
    disabledButtons,
    openAchieveMenu,
}) => {
    const [loading, setLoading] = useState({})

    const getUpgradeData = upgradeType => {
        return playerUpgrades.find(upgrade => upgrade.upgrade_type === upgradeType)
    }

    const handleClick = async (upgradeType, e) => {
        const targetButton = e.currentTarget
        handleFlyCoin(targetButton)

        sounds.approve.play()

        const { next_level_cost } = getUpgradeData(upgradeType)
        spendCoinsForUpgrade(next_level_cost)
        setLoading(prevLoading => ({ ...prevLoading, [upgradeType]: true }))

        try {
            await upgradePlayerBuffs(telegramId, upgradeType)
            await fetchPlayerUpgrades(telegramId)
        } catch (error) {
            console.error('Error during upgrade:', error)
        } finally {
            setLoading(prevLoading => ({ ...prevLoading, [upgradeType]: false }))
        }
    }

    const handleOpenAchieveMenu = () => {
        openAchieveMenu();
      };

    const handleCloseMenu = () => {
        sounds.close.play()
        closeMenu()
    }

    return (
        <div className="info-menu-overlay" onClick={handleCloseMenu}>
            <img src={require('../../images/leaf-frame-popup.webp')} alt="" className="leaf-image" />
            <div className="info-menu-content" onClick={e => e.stopPropagation()}>
                <div className="info-header">
                    <div className="menu-item">
                        <div
                            className={clsx('menu-btn', { disabled: disabledButtons })}
                            onClick={!disabledButtons ? null : null}
                        >
                            <img src={LeftMenuIcon} alt="Menu" className="menu-icon" />
                        </div>

                        <div
                            className={clsx('menu-btn', { disabled: disabledButtons })}
                            onClick={!disabledButtons ? handleOpenAchieveMenu : null}
                        >
                            <img src={RightDisableMenuIcon} alt="Menu" className="menu-icon" />
                        </div>
                    </div>
                    <div
                        className={clsx('close-menu-btn', { disabled: disabledButtons })}
                        onClick={!disabledButtons ? handleCloseMenu : null}
                    >
                        <img src={require('../../images/icons/close.png')} alt="Close" className="close-menu-icon" />
                    </div>
                </div>
                <div className="info-table-wrapper">
                    <table className="info-table">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="image-storage-back-icon">
                                        <img
                                            src={require('../../images/icons/capi-storage.png')}
                                            alt="Coin Storage"
                                            className="storage-img"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <h4>STORAGE</h4>
                                    <p>Expand the size of $COGA STORAGE</p>
                                </td>
                                <td>
                                    <button
                                        className={clsx('achieve-action-btn', {
                                            'achieve-blue-action-btn':
                                                getUpgradeData('storage_power')?.next_level_cost === 0 ||
                                                coins - getUpgradeData('storage_power')?.next_level_cost < 0,
                                            'achieve-green-action-btn':
                                                getUpgradeData('storage_power')?.next_level_cost > 0 &&
                                                coins - getUpgradeData('storage_power')?.next_level_cost >= 0,
                                        })}
                                        onClick={e => handleClick('storage_power', e)}
                                        disabled={
                                            disabledButtons ||
                                            loading['storage_power'] ||
                                            coins - getUpgradeData('storage_power')?.next_level_cost < 0 ||
                                            getUpgradeData('storage_power')?.next_level_cost === 0
                                        }
                                    >
                                        <img
                                            src={require('../../images/icons/coin.png')}
                                            alt="Coin"
                                            className="coin-btn-icon"
                                        />{' '}
                                        {getUpgradeData('storage_power')?.next_level_cost
                                            ? getUpgradeData('storage_power')?.next_level_cost.toLocaleString('de-DE')
                                            : 'MAX'}
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="image-back-icon">
                                        <div className={`pet-img ${selectedCharacter}`}></div>
                                    </div>
                                </td>
                                <td>
                                    <h4>TAP TAP</h4>
                                    <p>Increase Poomby Tap</p>
                                </td>
                                <td>
                                    <button
                                        className={clsx('achieve-action-btn', {
                                            'achieve-blue-action-btn':
                                                getUpgradeData('tap_power')?.next_level_cost === 0 ||
                                                coins - getUpgradeData('tap_power')?.next_level_cost < 0,
                                            'achieve-green-action-btn':
                                                getUpgradeData('tap_power')?.next_level_cost > 0 &&
                                                coins - getUpgradeData('tap_power')?.next_level_cost >= 0,
                                        })}
                                        onClick={e => handleClick('tap_power', e)}
                                        disabled={
                                            disabledButtons ||
                                            loading['tap_power'] ||
                                            coins - getUpgradeData('tap_power')?.next_level_cost < 0 ||
                                            getUpgradeData('tap_power')?.next_level_cost === 0
                                        }
                                    >
                                        <img
                                            src={require('../../images/icons/coin.png')}
                                            alt="Coin"
                                            className="coin-btn-icon"
                                        />{' '}
                                        {getUpgradeData('tap_power')?.next_level_cost
                                            ? getUpgradeData('tap_power')?.next_level_cost.toLocaleString('de-DE')
                                            : 'MAX'}
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="image-back-icon">
                                        <img
                                            src={require('../../images/icons/autotap.png')}
                                            alt="Auto Tap"
                                            className="auto-tap-img"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <h4>AUTO TAP</h4>
                                    <p>Farm more $COGA TOKENS</p>
                                </td>
                                <td>
                                    <button
                                        className={clsx('achieve-action-btn', {
                                            'achieve-blue-action-btn':
                                                getUpgradeData('auto_tap_power')?.next_level_cost === 0 ||
                                                coins - getUpgradeData('auto_tap_power')?.next_level_cost < 0,
                                            'achieve-green-action-btn':
                                                getUpgradeData('auto_tap_power')?.next_level_cost > 0 &&
                                                coins - getUpgradeData('auto_tap_power')?.next_level_cost >= 0,
                                        })}
                                        onClick={e => handleClick('auto_tap_power', e)}
                                        disabled={
                                            disabledButtons ||
                                            loading['auto_tap_power'] ||
                                            coins - getUpgradeData('auto_tap_power')?.next_level_cost < 0 ||
                                            getUpgradeData('auto_tap_power')?.next_level_cost === 0
                                        }
                                    >
                                        <img
                                            src={require('../../images/icons/coin.png')}
                                            alt="Coin"
                                            className="coin-btn-icon"
                                        />{' '}
                                        {getUpgradeData('auto_tap_power')?.next_level_cost
                                            ? getUpgradeData('auto_tap_power')?.next_level_cost.toLocaleString('de-DE')
                                            : 'MAX'}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default InfoMenu
