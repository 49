// FlyingCoins.js
import React from 'react'
import coinImg from '../../images/icons/coin.png'

const FlyingCoins = ({ flyingCoin, flyingCoins }) => {
    return (
        <>
            {flyingCoin && (
                <img
                    src={coinImg}
                    alt="Flying coin"
                    className="flying-coin"
                    style={{
                        position: 'fixed',
                        left: `${flyingCoin.startX}px`,
                        top: `${flyingCoin.startY}px`,
                        '--startX': `${flyingCoin.startX}px`,
                        '--startY': `${flyingCoin.startY}px`,
                        '--endX': `${flyingCoin.endX}px`,
                        '--endY': `${flyingCoin.endY}px`,
                        animation: `flyCoin 1s ease-in-out forwards`,
                        width: '30px',
                        height: '30px',
                        zIndex: 1000,
                    }}
                />
            )}

            {flyingCoins.length > 0 &&
                flyingCoins.map(coin => (
                    <img
                        key={coin.id}
                        src={coinImg}
                        alt="Flying coin"
                        className="flying-coin"
                        style={{
                            position: 'fixed',
                            left: `${coin.startX}px`,
                            top: `${coin.startY}px`,
                            '--startX': `${coin.startX}px`,
                            '--startY': `${coin.startY}px`,
                            '--endX': `${coin.endX}px`,
                            '--endY': `${coin.endY}px`,
                            animation: `flyCoin 1s ease-in-out forwards`,
                            width: '20px',
                            height: '20px',
                            zIndex: 1000,
                        }}
                    />
                ))}
        </>
    )
}

export default FlyingCoins
