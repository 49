import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import useWebSocket from 'react-use-websocket'
import clsx from 'clsx'
import './Gameplay.css'
import coinGame from '../../images/icons/autotap.png'
import LevelInfo from './../LevelInfo/LevelInfo'
import InfoMenu from './../InfoMenu/InfoMenu'
import AchieveMenu from './../AchieveMenu/AchieveMenu'
import coinImg from '../../images/icons/coin.png'
import LeftMenuIcon from '../../images/icons/left-menu.webp'
import RightMenuIcon from '../../images/icons/right-menu.webp'
import { maxEnergyValue, characterImages } from '../../constants'
import CoinAnimation from './../CoinAnimation/CoinAnimation'
import FlyingShapes from './../FlyingShapes/FlyingShapes'

import { sounds, startInterval, stopInterval, formatValue } from '../../utils'
import EnergyTimer from './../EnergyTimer/EnergyTimer'
import { nanoid } from 'nanoid'
import Storage from './../Storage/Storage'
import FlyingCoins from '../FlyingCoins/FlyingCoins'

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL

const Gameplay = ({ player, selectedCharacter }) => {
    const [isPopupVisible, setPopupVisible] = useState(false)
    const [isInfoMenuVisible, setInfoMenuVisible] = useState(false)
    const [isAchieveMenuVisible, setAchieveMenuVisible] = useState(false)
    const [isHighTapSpeed, setIsHighTapSpeed] = useState(false)
    const [coins, setCoins] = useState(player.tokens_earned)
    const [displayedCoins, setDisplayedCoins] = useState(coins)
    const [energy, setEnergy] = useState(player?.energy || maxEnergyValue)
    const [storageTokens, setStorageTokens] = useState(player.soft_tokens)
    const [playerUpgrades, setPlayerUpgrades] = useState([])
    const [coinAnimations, setCoinAnimations] = useState([])
    const [shapeAnimations, setShapeAnimations] = useState([])
    const [isCoinAnimating, setIsCoinAnimating] = useState(false)
    const [flyingCoin, setFlyingCoin] = useState(null)
    const [flyingCoins, setFlyingCoins] = useState([])
    const [playerLevel, setPlayerLevel] = useState(player.player_lvl)
    const [activeImage, setActiveImage] = useState(0)
    const [tapPower, setTapPower] = useState(1)

    const totalClicks = useRef(0)
    const sentClicks = useRef(0)

    const timerRef = useRef(null)
    const energyIntervalRef = useRef(null)
    const tapSpeedIntervalRef = useRef(null)
    const clickCount = useRef(0)
    const isHighTapSpeedRef = useRef(isHighTapSpeed)
    const prevCoins = useRef(coins)
    const isCharacterAnimatingRef = useRef(false)
    const activeTouches = useRef(new Set())

    const initialCoins = parseFloat(sessionStorage.getItem('coins')) || player.tokens_earned
    const initialEnergy = parseFloat(sessionStorage.getItem('energy')) || player?.energy || maxEnergyValue

    const { sendMessage, lastMessage } = useWebSocket(WEBSOCKET_URL, {
        shouldReconnect: () => true,
    })

    useLayoutEffect(() => {
        setCoins(initialCoins)
        setEnergy(initialEnergy)
    }, [])

    // Зберігаємо значення energy та coins в sessionStorage, коли вони змінюються
    useEffect(() => {
        sessionStorage.setItem('coins', coins)
        sessionStorage.setItem('energy', energy)
    }, [coins, energy])

    useEffect(() => {
        if (lastMessage !== null) {
            try {
                const data = JSON.parse(lastMessage.data)
                switch (data.operation) {
                    case 'player_click':
                        const { current_energy, tokens_earned, player_lvl: level } = data
                        setEnergy(current_energy)
                        setCoins(tokens_earned)
                        console.log('playerLevel:', playerLevel)
                        console.log('level:', level)
                        if (playerLevel < level) {
                            sounds.approve.play()
                            setPlayerLevel(level)
                        }
                        break
                    case 'read_player':
                        const { energy, tokens_earned: newTokens, soft_tokens, player_lvl } = data.player
                        setEnergy(energy)
                        setCoins(newTokens)
                        setStorageTokens(soft_tokens)
                        setPlayerLevel(player_lvl)
                        break
                    case 'get_player_upgrades':
                        const { upgrades } = data
                        const tapUpgrade = upgrades.find(upgrade => upgrade.upgrade_type === 'tap_power')
                        setPlayerUpgrades(upgrades)
                        setTapPower(tapUpgrade.current_power)
                        break
                    case 'convert_soft_tokens':
                        const {
                            tokens_earned: newTokensAfterStorageConverted,
                            soft_tokens: softTokensAfterStorageConverted,
                        } = data
                        setStorageTokens(softTokensAfterStorageConverted)
                        setCoins(newTokensAfterStorageConverted)
                        break
                    default:
                        break
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error)
            }
        }
    }, [lastMessage])

    useEffect(() => {
        if (coins !== prevCoins.current) {
            const increment = (coins - prevCoins.current) / 30
            let currentCoins = prevCoins.current

            const animateCoins = () => {
                currentCoins += increment
                if ((increment > 0 && currentCoins >= coins) || (increment < 0 && currentCoins <= coins)) {
                    currentCoins = coins
                }
                setDisplayedCoins(currentCoins)

                if (currentCoins !== coins) {
                    requestAnimationFrame(animateCoins)
                } else {
                    prevCoins.current = coins
                }
            }

            if (coins > prevCoins.current) {
                setIsCoinAnimating(true)
                setTimeout(() => setIsCoinAnimating(false), 500)
            }

            requestAnimationFrame(animateCoins)
        }
    }, [coins])

    useEffect(() => {
        const requestPlayerUpgrades = () => {
            fetchPlayerUpgrades()
        }

        if (player?.telegram_id) {
            requestPlayerUpgrades()
            startTapSpeedInterval()

            return () => {
                stopTapSpeedInterval()
                stopEnergyUpdateInterval()
            }
        }
    }, [player?.telegram_id])

    useEffect(() => {
        return () => {
            stopSendingClicks()
            stopTapSpeedInterval()
            stopEnergyUpdateInterval()
        }
    }, [])

    useEffect(() => {
        const requestPlayerData = () => {
            const requestData = {
                operation: 'read_player',
                data: {
                    telegram_id: player.telegram_id,
                },
            }
            sendMessage(JSON.stringify(requestData))
        }

        if (energy < maxEnergyValue) {
            stopEnergyUpdateInterval()
            return
        }
        if (energy === maxEnergyValue) {
            requestPlayerData()
            startEnergyUpdateInterval(requestPlayerData)
        }

        return () => {
            stopEnergyUpdateInterval()
        }
    }, [energy, player.telegram_id])

    const handleFlyCoin = targetElement => {
        const coinIcon = document.querySelector('.coin-icon')
        if (coinIcon && targetElement) {
            const coinRect = coinIcon.getBoundingClientRect()
            const targetRect = targetElement.getBoundingClientRect()

            setFlyingCoin({
                startX: coinRect.left + window.scrollX + coinRect.width / 2,
                startY: coinRect.top + window.scrollY + coinRect.height / 2,
                endX: targetRect.left + window.scrollX + targetRect.width / 2,
                endY: targetRect.top + window.scrollY + targetRect.height / 2 - 20,
            })

            setTimeout(() => setFlyingCoin(null), 1000)
        }
    }

    const handleFlyCoins = targetElement => {
        if (storageTokens > 0) {
            const coinIcon = document.querySelector('.progress-table-img')
            if (coinIcon && targetElement) {
                const coinRect = coinIcon.getBoundingClientRect()
                const targetRect = targetElement.getBoundingClientRect()

                const flyingCoinsArray = []
                for (let i = 0; i < 5; i++) {
                    setTimeout(() => {
                        flyingCoinsArray.push({
                            id: i,
                            startX: coinRect.left + window.scrollX + coinRect.width / 2,
                            startY: coinRect.top + window.scrollY + coinRect.height / 2,
                            endX: targetRect.left + window.scrollX + targetRect.width / 2,
                            endY: targetRect.top + window.scrollY + targetRect.height / 2 - 20,
                        })
                        setFlyingCoins([...flyingCoinsArray])
                    }, i * 100)

                    setTimeout(() => {
                        setFlyingCoins([])
                    }, 1500)
                }
            }
        }
    }

    const fetchPlayerUpgrades = () => {
        const requestData = {
            operation: 'get_player_upgrades',
            data: {
                telegram_id: player.telegram_id,
            },
        }
        sendMessage(JSON.stringify(requestData))
    }

    const handleTouchStart = e => {
        if (energy === 0) return
        sounds.tap.play()

        if (isCharacterAnimatingRef.current) {
            isCharacterAnimatingRef.current = false
            setTimeout(() => {
                isCharacterAnimatingRef.current = true
            }, 0)
        } else {
            isCharacterAnimatingRef.current = true
        }

        setActiveImage(prev => (prev === 0 ? 1 : 0))

        setTimeout(() => {
            isCharacterAnimatingRef.current = false
        }, 1000)

        const touchesArray = Array.from(e.touches)
        setCoins(prevCoins => prevCoins + touchesArray.length * tapPower)
        setEnergy(prevEnergy => Math.max(prevEnergy - touchesArray.length, 0))

        totalClicks.current += touchesArray.length
        clickCount.current += touchesArray.length

        startSendingClicks()

        touchesArray.forEach(touch => {
            const touchId = touch.identifier
            if (!activeTouches.current.has(touchId)) {
                activeTouches.current.add(touchId)
                const x = touch.clientX
                const y = touch.clientY
                handleCharacterClick(x, y)
            }
        })
    }

    const handleTouchEnd = e => {
        Array.from(e.changedTouches).forEach(touch => {
            const touchId = touch.identifier
            if (activeTouches.current.has(touchId)) {
                activeTouches.current.delete(touchId)
            }
        })
    }

    const handleCharacterClick = (x, y) => {
        setCoinAnimations(prevAnimations => {
            const newAnimations = [...prevAnimations, { id: nanoid(5), x, y }]
            return newAnimations.length > 20 ? newAnimations.slice(newAnimations.length - 20) : newAnimations
        })

        setShapeAnimations(prevAnimations => {
            const newShapes = [...prevAnimations, { id: nanoid(5), x, y, isTurboMode: isHighTapSpeed }]
            return newShapes.length > 2 ? newShapes.slice(newShapes.length - 2) : newShapes
        })
    }

    const startSendingClicks = () => {
        stopEnergyUpdateInterval()
        if (!timerRef.current) {
            timerRef.current = setInterval(() => {
                sendClicksToServer()
            }, 1000)
        }
    }

    const stopSendingClicks = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current)
            timerRef.current = null
            setShapeAnimations([])
            setCoinAnimations([])
        }
    }

    const sendClicksToServer = () => {
        const clicksToSend = totalClicks.current - sentClicks.current

        if (clicksToSend > 0) {
            if (energy > 0 && energy - clicksToSend <= 0) {
                const clickData = {
                    operation: 'player_click',
                    data: {
                        telegram_id: player.telegram_id,
                        clicks_count: energy,
                    },
                }
                sendMessage(JSON.stringify(clickData))
                sentClicks.current = totalClicks.current
                setEnergy(0)
                stopSendingClicks()
                return
            }
            const clickData = {
                operation: 'player_click',
                data: {
                    telegram_id: player.telegram_id,
                    clicks_count: clicksToSend,
                },
            }
            sendMessage(JSON.stringify(clickData))
            sentClicks.current += clicksToSend
        }

        if (totalClicks.current === sentClicks.current) {
            stopSendingClicks()
        }
    }

    const startEnergyUpdateInterval = requestPlayerData => {
        if (!energyIntervalRef.current) {
            energyIntervalRef.current = startInterval(() => {
                requestPlayerData()
            }, 30000)
        }
    }

    const stopEnergyUpdateInterval = () => {
        stopInterval(energyIntervalRef)
    }

    const startTapSpeedInterval = () => {
        if (!tapSpeedIntervalRef.current) {
            tapSpeedIntervalRef.current = setInterval(() => {
                const tapsPerMinute = (clickCount.current * 60) / 10

                if (tapsPerMinute >= 120) {
                    if (!isHighTapSpeedRef.current) {
                        sounds.turbo.play()
                        setIsHighTapSpeed(true)
                        isHighTapSpeedRef.current = true
                    }
                } else {
                    if (isHighTapSpeedRef.current) {
                        setIsHighTapSpeed(false)
                        isHighTapSpeedRef.current = false
                    }
                }

                clickCount.current = 0
            }, 10000)
        }
    }

    const stopTapSpeedInterval = () => {
        if (tapSpeedIntervalRef.current) {
            clearInterval(tapSpeedIntervalRef.current)
            tapSpeedIntervalRef.current = null
        }
    }

    const spendCoinsForUpgrade = upgradeCost => {
        setCoins(prevCoins => (prevCoins - upgradeCost < 0 ? prevCoins : prevCoins - upgradeCost))
    }

    const earnCoinsForSubscription = upgradeCost => {
        setCoins(prevCoins => prevCoins + upgradeCost)
    }

    const togglePopup = () => {
        if (isInfoMenuVisible || isAchieveMenuVisible) {
            setInfoMenuVisible(false)
            setAchieveMenuVisible(false)
        }
        sounds.close.play()
        setPopupVisible(!isPopupVisible)
    }

    const toggleAchieveMenu = () => {
        setAchieveMenuVisible(!isAchieveMenuVisible)
        setInfoMenuVisible(false)
        sounds.close.play()
    }

    const toggleInfoMenu = () => {
        setInfoMenuVisible(!isInfoMenuVisible)
        setAchieveMenuVisible(false)
        sounds.close.play()
    }

    return (
        <div className="page">
            <div className="container">
                <div
                    className={clsx('gameplay-wrapper', {
                        'move-bg-up': isInfoMenuVisible,
                        'high-tap-speed': isHighTapSpeed,
                    })}
                >
                    <div className="player-info">
                        <div className="level-item">
                            <div className="account-wrapper" onClick={togglePopup}>
                                <div className={`selected-pet-img ${selectedCharacter}`}></div>
                            </div>
                            <h3>{playerLevel} LVL</h3>
                        </div>
                        <Storage
                            player={player}
                            sendMessage={sendMessage}
                            storageTokens={storageTokens}
                            setStorageTokens={setStorageTokens}
                            handleFlyCoins={handleFlyCoins}
                            playerUpgrades={playerUpgrades}
                        />
                    </div>
                    <div
                        className={clsx('game-field-wrapper', { 'move-up': isInfoMenuVisible || isAchieveMenuVisible })}
                    >
                        <div
                            className={clsx('coins-energy-wrapper', {
                                'move-up': isInfoMenuVisible || isAchieveMenuVisible,
                            })}
                        >
                            <div className={clsx('coins-wrapper', { 'coin-animate': isCoinAnimating })}>
                                <img src={coinImg} alt="Coin Icon" className="coin-icon" />
                                <h1>{displayedCoins > 0 ? formatValue(displayedCoins) : 0}</h1>
                            </div>
                            <div className="energy-wrapper">
                                <div className="energy-item">
                                    <p className="yellow-text">
                                        {energy} / {maxEnergyValue}
                                    </p>
                                </div>
                            </div>
                            <EnergyTimer
                                energy={energy}
                                setEnergy={setEnergy}
                                maxEnergyValue={maxEnergyValue}
                                sendMessage={sendMessage}
                                player={player}
                            />
                        </div>

                        {coinAnimations.map(anim => (
                            <CoinAnimation
                                key={anim.id}
                                x={anim.x}
                                y={anim.y}
                                removeCoin={() => setCoinAnimations(prev => prev.filter(a => a.id !== anim.id))}
                            />
                        ))}

                        {shapeAnimations.map(anim => (
                            <FlyingShapes
                                key={anim.id}
                                x={anim.x}
                                y={anim.y}
                                isTurboMode={anim.isTurboMode}
                                character={player?.character.name}
                            />
                        ))}

                        <div
                            className={clsx('selected-character-display', {
                                'move-up': isInfoMenuVisible,
                                hidden: isPopupVisible,
                            })}
                        >
                            <img src={coinGame} className="coin-game" alt="Coin Game" />

                            {selectedCharacter ? (
                                <>
                                    <div
                                        className={clsx({
                                            [activeImage === 0 ? 'active' : 'inactive']: true,
                                        })}
                                    >
                                        <img
                                            src={characterImages[selectedCharacter].idle}
                                            alt={selectedCharacter}
                                            className={clsx('character', {
                                                'idle-character': !isCharacterAnimatingRef.current,
                                            })}
                                        />
                                        <img
                                            src={characterImages[selectedCharacter].animate}
                                            alt={selectedCharacter}
                                            className={clsx('character', {
                                                'animate-character ': isCharacterAnimatingRef.current,
                                            })}
                                        />
                                    </div>
                                    <div
                                        className={clsx({
                                            [activeImage === 1 ? 'active' : 'inactive']: true,
                                        })}
                                    >
                                        <img
                                            src={characterImages[selectedCharacter].idle}
                                            alt={selectedCharacter}
                                            className={clsx('character', {
                                                'idle-character': !isCharacterAnimatingRef.current,
                                            })}
                                        />
                                        <img
                                            src={characterImages[selectedCharacter].animate}
                                            alt={selectedCharacter}
                                            className={clsx('character', {
                                                'animate-character ': isCharacterAnimatingRef.current,
                                            })}
                                        />
                                    </div>
                                </>
                            ) : (
                                <p>No character selected</p>
                            )}
                            <div
                                className="clicked-images-wrapper"
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                            />
                        </div>
                    </div>

                    <div className="bottom-info">
                        <div className="menu-btn" onClick={toggleInfoMenu}>
                            <img src={LeftMenuIcon} alt="Menu" className="menu-icon" />
                        </div>
                        <div className="menu-btn" onClick={toggleAchieveMenu}>
                            <img src={RightMenuIcon} alt="Menu" className="menu-icon" />
                        </div>
                    </div>
                </div>
            </div>

            {isPopupVisible && (
                <LevelInfo
                    closePopup={togglePopup}
                    selectedCharacter={selectedCharacter}
                    player={player}
                    level={playerLevel}
                />
            )}

            {isAchieveMenuVisible && (
                <AchieveMenu
                    closeMenu={toggleAchieveMenu}
                    telegramId={player.telegram_id}
                    earnCoinsForSubscription={earnCoinsForSubscription}
                    openInfoMenu={toggleInfoMenu}
                />
            )}

            {isInfoMenuVisible && (
                <InfoMenu
                    closeMenu={toggleInfoMenu}
                    telegramId={player.telegram_id}
                    playerUpgrades={playerUpgrades}
                    fetchPlayerUpgrades={fetchPlayerUpgrades}
                    spendCoinsForUpgrade={spendCoinsForUpgrade}
                    coins={coins}
                    selectedCharacter={selectedCharacter}
                    handleFlyCoin={handleFlyCoin}
                    disabledButtons={flyingCoin}
                    openAchieveMenu={toggleAchieveMenu}
                />
            )}
            <FlyingCoins flyingCoin={flyingCoin} flyingCoins={flyingCoins} />
        </div>
    )
}

export default Gameplay
