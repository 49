import React, { useState } from 'react';
import './Start.css';
import InformationSection from './../InformationSection/InformationSection';
import infoIcon from '../../images/icons/info-icon.png';

function Start({ onPlay,isSubscribed }) {
  console.log('isSubscribed:', isSubscribed)
  const [showPopup, setShowPopup] = useState(false); 
  const [popupContent, setPopupContent] = useState(''); 
  const [buttonText, setButtonText] = useState(''); 

  const handleInfoClick = () => {
    setPopupContent
    (
      <div className="popup-text-content">
        <p><b>How to become the coolest POOMBY CO-OWNER?</b>
        </p>
        <p>Subscribe and invite your friends to the game right now!</p>
        <p>You can receive numerous bonuses in the form of exciting characters, unique add-ons, game bonuses, a lot of coins and real tokens that other users won't have!!</p>
        <p>This offer is only available for users who start playing our game right now!.</p>
      </div>
    );
    setButtonText('COOL');
    setShowPopup(true);
  };

  const handleClosePopup = () => setShowPopup(false);

  return (
    <div className="page">
      <div className="container">
        <div className="start-page-wrapper">
          <div className="subscribe-wrapper">
            <div className="subscribe-content">
              <div className="icon" onClick={handleInfoClick}>
                <img src={infoIcon} alt="Info Icon" className="info-icon" />
              </div>
              <p>"IN POOMB WE TRUST, IN POOMB WE EARN"</p>
              <p>What's $COGA TOKEN?</p>
              <p>
                Comet Games token (COGA) is a native token created by gamedev company Comet Space
                and will be integrated into all Comet Space company projects.
                The first project with token integration is Poomb Creatures.
              </p>
              <p>What's POOMB Creatures?</p>
              <p>
                It' s a whole ecosystem of Telegram clicker, NFT,
                Crypto exchange and mobile game for the iOS Store and
                Google Play Market platforms that combines popular elements of the
                Tamagotchi genre with advanced blockchain technologies.
              </p>
              <p>So, awaken your inner POOMB!
                Choose your Poomb Pet and start earning $COGA TOKEN!!
              </p>
            </div>
            <button
                className={`btn-action  ${isSubscribed ? "disabled" : ""}`}
              onClick={() => window.open('https://t.me/poomb_game/44', '_blank')}
            disabled={isSubscribed}>
                Subscribe
            </button>
          </div>
          <div className="action-wrapper">
            <button className={`btn-accept ${!isSubscribed ? "disabled" : ""}`} onClick={onPlay} disabled={!isSubscribed}>
              Play
            </button>
          </div>
        </div>
      </div>
     
      {showPopup && (
        <InformationSection
          onClose={handleClosePopup}
          content={popupContent}
          buttonText={buttonText}
        />
      )}
    </div>
  );
}

export default Start;